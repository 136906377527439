<template>
    <v-list>
        <template v-for="(variant, index) in variants">
            <v-list-item class="pa-0" :key="variant.code">
                <v-list-item-avatar class="dense" v-if="variant._id && !viewOnly">
                    <v-radio-group v-model="variant.isDefaultVariant" column>
                        <v-icon v-if="variant.isDefaultVariant">mdi-radiobox-marked</v-icon>
                        <v-radio v-else @change="$emit('defaultVariantChanged', variant)"></v-radio>
                    </v-radio-group>
                </v-list-item-avatar>
                <v-list-item-icon class="text-center" :class="!viewOnly ? '' : 'productImage'">
                    <div>
                        <file-upload ref="fileupload" v-model="variant.images" :value="variant.images" label=""
                            accept="image/*" :fileName="variant.code.trim()" :customAttributes="variant.variant"
                            :showClose="!variant._id" v-if="!variant._id" width="72" height="72">
                        </file-upload>
                        <image-card :src="variant?.images[0]?.path" width="72" height="72" v-else>
                        </image-card>
                        <v-radio-group v-model="variant.isDefaultVariant" column v-if="!variant._id">
                            <v-icon v-if="variant.isDefaultVariant">mdi-radiobox-marked</v-icon>
                            <v-radio v-else @change="$emit('defaultVariantChanged', variant)"></v-radio>
                        </v-radio-group>
                    </div>
                </v-list-item-icon>
                <v-list-item-content :class="!viewOnly ? 'pl-2' : 'pl-n2'">
                    <template v-if="!variant._id">
                        <v-text-field class="mt-1 mb-n4" outlined dense label="Title"
                            v-model="variant.title"></v-text-field>
                        <v-text-field class="mb-n4" outlined dense label="Code" v-model="variant.code"></v-text-field>
                        <v-text-field outlined dense type="number" v-model="variant.price" label="Selling Price"
                            class="mb-n4" :readonly="variant._id" v-if="!variant._id">
                        </v-text-field>
                        <v-text-field outlined dense type="number" v-model="variant.offerprice" label="Offer Price"
                            class="mb-n4" :readonly="variant._id" v-if="!variant._id">
                        </v-text-field>
                        <!-- <v-text-field outlined dense type="number" v-model="variant.stock" label="Current Stock"
                            class="mb-n4" :readonly="variant._id" v-if="!variant._id">
                        </v-text-field>
                        <v-text-field outlined dense type="number" v-model="variant.reserveStock"
                            label="Reserve Stock Level" class="mb-n6" :readonly="variant._id" v-if="!variant._id">
                        </v-text-field> -->
                        <div class="text-right pa-1">
                            <v-btn x-small :style="theme" class="mr-1" @click="$emit('link', index)"><v-icon
                                    small>mdi-link-box-variant</v-icon>Link
                                Product</v-btn>
                            <v-btn x-small :style="themeInverted" outlined
                                @click="$emit('delete', { index: index, _id: variant._id })"><v-icon
                                    small>mdi-delete</v-icon>Delete</v-btn>
                        </div>
                    </template>
                    <template v-else>
                        <p class="text-body-2 font-weight-medium link"
                            @click="forceNavigateTo(`/app/products/view/${variant._id}`)">{{ variant.title }}
                            <v-icon color="green" v-if="viewOnly && variant.isDefaultVariant">mdi-check</v-icon>
                        </p>
                        <p class="text-caption font-weight-light mt-n1">{{ variant.code }}</p>
                        <v-row>
                            <v-col cols="6">
                                <p>Price: <v-icon x-small>mdi-currency-inr</v-icon>{{ variant.price }}</p>
                            </v-col>
                            <v-col cols="6">
                                <p>Offer: <v-icon x-small>mdi-currency-inr</v-icon>{{ variant.offerprice }}</p>
                            </v-col>
                        </v-row>
                    </template>
                </v-list-item-content>
                <v-list-item-action v-if="variant._id && !viewOnly" class="action">
                    <v-btn title="Link Product" small @click="$emit('link', index)" icon v-if="!variant._id">
                        <v-icon>mdi-link-box-variant</v-icon>
                    </v-btn>
                    <v-btn small @click="$emit('edit', variant._id)" icon v-if="variant._id">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn small @click="$emit('delete', { index: index, _id: variant._id })" icon>
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-divider :key="index" class="my-2"></v-divider>
        </template>
    </v-list>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
        viewOnly: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        variants: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        }
    },
}
</script>

<style scoped>
.dense {
    min-width: 24px !important;
    width: 24px !important;
    height: 24px !important;
    margin-right: 2px !important;
}

.productImage {
    margin-right: 12px !important;
}

.action {
    margin-left: 2px !important;
    justify-content: center !important;
}
</style>