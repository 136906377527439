<template>
    <div>
        <p class="text-h6 mt-4">Available Variants</p>
        <available-variants-card v-model="variantsAvailable" v-if="isMobile"
            @defaultVariantChanged="setDefaultVariant($event)" @link="toggleLinkProductDialog($event)"
            @delete="deleteVariant($event.index, $event._id)" @edit="editVariant($event)"
            :viewOnly="viewOnly"></available-variants-card>
        <available-variants-table v-model="variantsAvailable" v-else @defaultVariantChanged="setDefaultVariant($event)"
            @link="toggleLinkProductDialog($event)" @edit="editVariant($event)"
            @delete="deleteVariant($event.index, $event._id)" :viewOnly="viewOnly"></available-variants-table>
        <div class="text-right">
            <v-btn small outlined @click="handleGenerateVariants" class="my-4" :style="themeInverted"
                v-if="!viewOnly">Generate
                Variants</v-btn>
        </div>
        <app-dialog title="Select existing product to link" width="30%" :show="showLinkProductDialog"
            @close="toggleLinkProductDialog()">
            <product-drop-down v-model="linkedProduct"></product-drop-down>
            <div class="text-right">
                <v-btn small @click="linkProduct()" class="mr-1" :style="theme">Select</v-btn>
                <v-btn small outlined @click="toggleLinkProductDialog()" :style="themeInverted">Cancel</v-btn>
            </div>
        </app-dialog>
    </div>
</template>

<script>
import _ from 'lodash';
import ImageCard from '../../../components/ImageCard.vue';
import RowItem from '../../../components/RowItem.vue';
import appConstants from '../../../utils/appConstants';
import AppDialog from '../../../components/AppDialog.vue';
import ProductDropDown from './ProductDropDown.vue';
import AvailableVariantsTable from './AvailableVariantsTable.vue';
import AvailableVariantsCard from './AvailableVariantsCard.vue';

export default {
    components: { ImageCard, RowItem, AppDialog, ProductDropDown, AvailableVariantsTable, AvailableVariantsCard },
    props: {
        product: {
            type: Object,
            required: true,
        },
        images: {
            type: Array,
            default: () => [],
        },
        variantConfigs: {
            type: Array,
            default: () => [],
        },
        existingVariants: {
            type: Array,
            default: () => [],
        },
        viewOnly: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            variantsAvailable: [], // Combines existing and newly generated variants,
            showLinkProductDialog: false,
            linkedProduct: undefined,
            selectedVariantIndex: undefined
        };
    },
    watch: {
        existingVariants(newValue, oldValue) {
            this.variantsAvailable = [...newValue]
        }
    },
    methods: {
        handleGenerateVariants() {
            const excludes = this.existingVariants.map((v) => v.variant);
            const newVariants = this.computedVariants(this.variantConfigs, excludes);

            // Add only new (missing) variants to the available list
            this.variantsAvailable = [
                ...this.existingVariants,
                ...newVariants,
            ];
            this.$emit('variantsChanged', this.variantsAvailable);
        },
        /**
         * This method will use cartesian technic, to generate all possible combinations of the variants.
         * @param { variantConfigs } array of variants configurations e.g. 
         * [{field: "size", values:['Small', 'Large']}, {field: "color", values:['Red', 'Orange', 'Yellow']}] 
         * @param { excludes } array of variants that need to be excluded while generating variants
         * e.g. [{color: "red"},{color: "orange"}, {size: "Small"}]
         */
        computedVariants(variantConfigs, excludes = []) {
            const valuesArrays = variantConfigs.map((variant) => variant.values);
            const variants = [];

            const cartesianProduct = (arrays, prefix = [], index = 0) => {
                if (index === arrays.length) {
                    const variant = {};
                    prefix.forEach((value, idx) => {
                        variant[variantConfigs[idx].field] = value;
                    });

                    if (!excludes.find((ex) => _.isEqual(ex, variant))) {
                        let isDefaultVariant = this.existingVariants.length == 0 && variants.length == 0
                        variants.push({
                            images: this.product._id ? _.cloneDeep(this.product.images) : _.cloneDeep(this.images),
                            code: `${this.product?.code}-${prefix.join('-').toLowerCase()}`,
                            title: `${this.product?.title} ${prefix.join('/')}`,
                            price: this.product?.price || 0,
                            offerprice: this.product?.offerprice || 0,
                            stock: 0,
                            reserveStock: 0,
                            variant,
                            isDefaultVariant
                        });
                    }
                    return;
                }
                arrays[index].forEach((value) => {
                    cartesianProduct(arrays, [...prefix, value], index + 1);
                });
            };
            //this.$emit('variantsChanged', variants);
            cartesianProduct(valuesArrays);
            return variants;
        },
        editVariant(id) {
            this.navigateTo(`/app/products/${id}`)
            window.location.reload();
        },
        async deleteVariant(index, id) {
            let deleteAction = true
            if (id && this.product._id) {
                deleteAction = await this.deleteItem("Are you sure you want to delete this Product?", appConstants.PRODUCTS_API + "/" + id)
            }
            if (deleteAction) {
                this.variantsAvailable.splice(index, 1);
                this.$emit('variantsChanged', this.variantsAvailable);
            }
        },
        toggleLinkProductDialog(index) {
            this.selectedVariantIndex = index
            this.showLinkProductDialog = !this.showLinkProductDialog;
        },
        linkProduct() {
            this.variantsAvailable[this.selectedVariantIndex]["_id"] = this.linkedProduct._id
            this.variantsAvailable[this.selectedVariantIndex]["images"] = this.linkedProduct.images
            this.variantsAvailable[this.selectedVariantIndex]["title"] = this.linkedProduct.title
            this.variantsAvailable[this.selectedVariantIndex]["linkProduct"] = true
            this.variantsAvailable[this.selectedVariantIndex]["price"] = this.linkedProduct.price
            this.variantsAvailable[this.selectedVariantIndex]["offerprice"] = this.linkedProduct.offerprice
            this.variantsAvailable[this.selectedVariantIndex]["stock"] = this.linkedProduct.stock
            this.variantsAvailable[this.selectedVariantIndex]["reserveStock"] = this.linkedProduct.reserveStock
            this.linkedProduct = undefined
            this.toggleLinkProductDialog()
        },
        async setDefaultVariant(variant) {
            this.variantsAvailable = this.variantsAvailable.map((item) => {
                if (item?.linkProduct || _.isEqual(item.variant, variant.variant)) {
                    return { ...item, isDefaultVariant: true };
                } else {
                    return { ...item, isDefaultVariant: false };
                }
            });
            this.$emit('variantsChanged', this.variantsAvailable);
            if (variant._id && this.product._id) {
                await this.putItem(`${appConstants.PRODUCTS_API}/${variant.parent}/defaultVariant/${variant._id}`);
            }
        }
    },
};
</script>

<style scoped></style>
